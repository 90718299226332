import React from 'react';
import {getFormInitialValues, reduxForm, formValueSelector} from 'redux-form'
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import * as PropTypes from 'prop-types';
import {PrimaryButton} from '../../../../common/Button';
import buttonMessages from '../../../../../intl/common/buttonMessages';
import {
    BUTTON_LABEL_CLOSE,
    BUTTON_LABEL_REOPEN,
    FORM_BUTTON_CLOSE,
    FORM_BUTTON_REOPEN,
    FORM_BUTTON_SAVE,
    FORM_BUTTON_TRANSFER_TO_REQUESTOR,
    BUTTON_LABEL_TRANSFER_TO_REQUESTOR,
    FORM_BUTTON_ESCALATE,
    BUTTON_LABEL_ESCALATE,
    FORM_BUTTON_ANSWER,
    BUTTON_LABEL_ANSWER,
    FORM_BUTTON_EDIT,
    FORM_BUTTON_TAKE_BACK,
    BUTTON_LABEL_TAKE_BACK,
    ASE_INTERNAL_TICKET_STATUS_CLOSED,
    ASE_INTERNAL_TICKET_STATUS_TRANSFERRED,
    ASE_INTERNAL_TICKET_STATUS_ANSWERED,
    ASE_INTERNAL_TICKET_STATUS_REOPENED,
    BUTTON_LABEL_SAVE_AND_SEND,
    BUTTON_LABEL_SAVE,
    FORM_STATUS_HISTORY_SECTION, ASE_INTERNAL_TICKET_FORM
} from '../../../../../constants/formConstants';
import {RequiredSpan} from '../../../../common/StyledComponents';
import {withRouter} from 'react-router-dom';
import get from 'get-value';
import ValidFormattedMessage from '../../../../common/ValidFormattedMessage';
import {throttle} from 'throttle-debounce';
import commonMessages from '../../../../../intl/common/commonMessages';
import {editAseInternalTicketListButtonVisibility} from '../../../../../constants/aseInternalTicketRights';
import {moduleRoles} from '../../../../../utils/roles';
import validationMessages from '../../../../../intl/common/validationMessages';

const SubmitButtons = ({handleSubmit, submitting, initialValues, readOnly, userRights, dirty, escalationSGs, answeringSGs, history, onSubmit, roles, ticketSG, canTakeBack, ticketHistory = [], userDetail}) => {

    const filteredAnsweringSGs = (answeringSGs || []).filter(sg => ticketHistory.some(historySg => get(historySg, "toSolutionGroup.id") === sg.id));
    const [selectedGroupForEscalate, setSelectedGroupForEscalate] = React.useState(escalationSGs && escalationSGs.length && escalationSGs[0].id);
    const [selectedGroupForAnswer, setSelectedGroupForAnswer] = React.useState(filteredAnsweringSGs && filteredAnsweringSGs.length && filteredAnsweringSGs[0].id);
    React.useEffect(() => {
        setSelectedGroupForEscalate(escalationSGs && escalationSGs.length && escalationSGs[0].id);
    }, [escalationSGs])
    React.useEffect(() => {
        setSelectedGroupForAnswer(filteredAnsweringSGs && filteredAnsweringSGs.length && filteredAnsweringSGs[0].id);
    }, [answeringSGs])

    const ticketStatus = get(initialValues, 'ticketSection.status');
    const statusClosed = ticketStatus === ASE_INTERNAL_TICKET_STATUS_CLOSED;

    const hasSomeEscalationSG = Array.isArray(escalationSGs) && escalationSGs.length;

    const canEscalateToSG = userRights.canEscalate && hasSomeEscalationSG;
    const canAnswerToSG = userRights.canAnswer && filteredAnsweringSGs && !!filteredAnsweringSGs.length;

    const renderCancelButton = () => {
        return (
            <PrimaryButton onClick={(e) => {
                e.preventDefault();
                history.goBack();
            }} className="btn mr-2 float-right">
                <FormattedMessage {...buttonMessages.CANCEL}/>
            </PrimaryButton>
        )
    };

    const renderBackButton = () => {
        return (
            <PrimaryButton onClick={(e) => {
                e.preventDefault();
                history.goBack();
            }} className="btn mr-2 float-right">
                <FormattedMessage {...buttonMessages.BACK}/>
            </PrimaryButton>
        )
    };

    const renderEditButton = () => {
        return (
            <PrimaryButton onClick={
                handleSubmit(values =>
                    onSubmit({
                        submitBy: FORM_BUTTON_EDIT,
                    }))
            } className="btn mr-2 float-right">
                <FormattedMessage {...buttonMessages.EDIT}/>
            </PrimaryButton>
        )
    };

    const renderButton = (button, message, changeToStatus, changeToSolutionGroup) => {
        return (
            <PrimaryButton onClick={throttle(1000, async (e) => {
                e.preventDefault();
                //Unfortunately haven't found any more elegant way to omit validation on MARK_AS_READ button click, this value is checked in REQUIRED validation
                handleSubmit(values =>
                    onSubmit({
                        newValues: {
                            ...values,
                            ticketSection: {
                                ...values.ticketSection,
                                status: changeToStatus,
                                ...(button === FORM_BUTTON_ESCALATE) && {
                                    assignedSolutionGroup: {
                                        id: selectedGroupForEscalate,
                                        name: escalationSGs.find(item => selectedGroupForEscalate === item.id).name
                                    }
                                },
                                ...(button === FORM_BUTTON_ANSWER) && {
                                    assignedSolutionGroup: {
                                        id: selectedGroupForAnswer,
                                        name: answeringSGs.find(item => selectedGroupForAnswer === item.id).name
                                    }
                                },
                                ...(button === FORM_BUTTON_TRANSFER_TO_REQUESTOR) && {
                                    assignedSolutionGroup: {
                                        id: changeToSolutionGroup.id,
                                        name: changeToSolutionGroup.name
                                    }
                                },
                                ...(button === FORM_BUTTON_TAKE_BACK) && {
                                    assignedSolutionGroup: {
                                        id: changeToSolutionGroup.id,
                                        name: changeToSolutionGroup.name
                                    }
                                },
                            }
                        },
                        oldValues: initialValues,
                        submitBy: button,
                    }))()
            })
            } className="btn mr-2 float-right" disabled={submitting || (!dirty && button === FORM_BUTTON_SAVE)}>
                <ValidFormattedMessage message={message} intlMessages={buttonMessages}/>
            </PrimaryButton>
        )
    };

    const renderSelectButton = (button, message, changeToStatus, availableOptions, value, setValue) => {
        const moveLeft = {marginLeft: -10, marginRight: 10};
        return (
            <>
                <div className="input-group-prepend">
                    {renderButton(button, message, changeToStatus)}
                </div>
                <select className="custom-select" value={value} onChange={e => setValue(e.target.value)} style={moveLeft}>
                    {availableOptions.map((item) => <option key={item.key} value={item.key}>{item.value}</option>)}
                </select>
            </>
        )
    };

    const renderTransferToRequestorButton = () => {
        const requestorSolutionGroup = get(initialValues, "requestorSolutionGroup", {});
        return renderButton(FORM_BUTTON_TRANSFER_TO_REQUESTOR, BUTTON_LABEL_TRANSFER_TO_REQUESTOR, ASE_INTERNAL_TICKET_STATUS_ANSWERED, requestorSolutionGroup);
    };

    const canAnswerToRequestor = () => {
        const isAssignedToSgL3 = get(initialValues, "ticketSection.assignedSolutionGroup.name", '').toLowerCase().includes('l3');
        const isRequestorSgInL1 = get(initialValues, "requestorSolutionGroup.name", '').toLowerCase().includes('l1') || get(initialValues, "ticketSection.solutionGroup.name", '').toLowerCase().includes('l1');
        const requestorGroupId = get(initialValues, "requestorSolutionGroup.id", {})
        const assignedGroupId = get(initialValues, "ticketSection.assignedSolutionGroup.id", {})
        return (requestorGroupId !== assignedGroupId) && (answeringSGs || []).some(sg => sg.id === requestorGroupId) && (!(isAssignedToSgL3 && isRequestorSgInL1));
    };

    const canClose = () => {
        const requestorGroupId = get(initialValues, "requestorSolutionGroup.id", {})
        const assignedGroupId = get(initialValues, "ticketSection.assignedSolutionGroup.id", {})
        const isAdmin = moduleRoles.isAdminAse(roles) || moduleRoles.isSGAdminAse(roles);
        const isRequestor = userDetail.ipn === initialValues.ticketSection.requestorIpn;
        return userRights.canClose && (requestorGroupId === assignedGroupId) && (isAdmin || isRequestor);
    };

    const renderReopenButton = () => {
        return renderButton(FORM_BUTTON_REOPEN, BUTTON_LABEL_REOPEN, ASE_INTERNAL_TICKET_STATUS_REOPENED);
    };

    const renderEscalateButton = () => {
        const value = selectedGroupForEscalate;
        const setValue = setSelectedGroupForEscalate;
        const availableOptions = escalationSGs.map(item => ({key: item.id, value: item.name}));
        return renderSelectButton(FORM_BUTTON_ESCALATE, BUTTON_LABEL_ESCALATE, ASE_INTERNAL_TICKET_STATUS_TRANSFERRED, availableOptions, value, setValue);
    };

    const renderAnswerButton = () => {
        const value = selectedGroupForAnswer;
        const setValue = setSelectedGroupForAnswer;
        const availableOptions = filteredAnsweringSGs.map(item => ({key: item.id, value: item.name}));
        return renderSelectButton(FORM_BUTTON_ANSWER, BUTTON_LABEL_ANSWER, ASE_INTERNAL_TICKET_STATUS_ANSWERED, availableOptions, value, setValue);
    };

    const renderTakeBackButton = () => {
        const type = get(canTakeBack, "takeBackType", '');
        return renderButton(FORM_BUTTON_TAKE_BACK, BUTTON_LABEL_TAKE_BACK, type === 'ANSWER' ? ASE_INTERNAL_TICKET_STATUS_ANSWERED : ASE_INTERNAL_TICKET_STATUS_TRANSFERRED, canTakeBack);
    };

    const ticketInNonEditableState = () => {
        const buttonVisibility = editAseInternalTicketListButtonVisibility.AS_SG_EDITOR;
        return !buttonVisibility.includes(ticketStatus);
    };

    if (readOnly) {
        let canSwitchToEdit = !statusClosed && !ticketInNonEditableState() && moduleRoles.isSGEditorAseInSG(roles, get(ticketSG, 'id', {default:''}));

        return (
            <div className="d-flex justify-content-end mt-3">
                <form className="form-group">
                    {renderBackButton()}
                    {canSwitchToEdit && renderEditButton()}
                    {canTakeBack && renderTakeBackButton()}
                    {userRights.canReopen && statusClosed && renderReopenButton()}
                </form>
            </div>
        )
    }
    return (
        <div className="d-flex justify-content-between mt-3">
            <div className="flex-column">
                <div><RequiredSpan>{'*\u00A0'}</RequiredSpan><FormattedMessage {...commonMessages.IS_MANDATORY}/></div>
                {!ticketStatus && !canEscalateToSG && <div><RequiredSpan><FormattedMessage {...validationMessages.INTERNAL_TICKET_CREATION_ESCALATION}/></RequiredSpan></div>}
            </div>
            <form>
                <div className="input-group">
                    {renderBackButton()}
                    {userRights.canCancel && renderCancelButton()}
                    {canEscalateToSG && renderEscalateButton()}
                    {canAnswerToSG && renderAnswerButton()}
                    {userRights.canAnswerToRequestor && canAnswerToRequestor() && renderTransferToRequestorButton()}
                    {canClose() && renderButton(FORM_BUTTON_CLOSE, BUTTON_LABEL_CLOSE, ASE_INTERNAL_TICKET_STATUS_CLOSED)}
                    {userRights.canSave && renderButton(FORM_BUTTON_SAVE, ticketStatus ? BUTTON_LABEL_SAVE : BUTTON_LABEL_SAVE_AND_SEND, ticketStatus)}
                </div>
            </form>
        </div>
    )
}

function getSolutionGroups(state, schemaType) {
    if (!state.solutionGroupInfo || !state.solutionGroupInfo[schemaType]) {
        return undefined;
    }
    const docType = get(state, 'ticket.formType') || ASE_INTERNAL_TICKET_FORM;
    return state.solutionGroupInfo[schemaType][docType];
}

SubmitButtons.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    readOnly: PropTypes.any,
    submitting: PropTypes.bool.isRequired,
    initialValues: PropTypes.object.isRequired,
    reset: PropTypes.func.isRequired,
    userRights: PropTypes.object.isRequired,
    roles: PropTypes.object.isRequired,
    ticketSG: PropTypes.any,
    canTakeBack: PropTypes.any,
};

const selector = (form, ...other) => (formValueSelector(form))(...other);
const mapStateToProps = (state, initialProps) => ({
    ticketHistory: selector(initialProps.form, state, `${FORM_STATUS_HISTORY_SECTION}`),
    initialValues: getFormInitialValues(initialProps.form)(state),
    roles: state.profile.userDetail.roles,
    escalationSGs: getSolutionGroups(state.aseInternalTicket, 'escalationSchemas'),
    answeringSGs: getSolutionGroups(state.aseInternalTicket, 'answeringSchemas'),
    ticketSG: get(state, 'aseInternalTicket.ticket.assignedSolutionGroup'),
    canTakeBack: get(state, 'aseInternalTicket.canTakeBack'),
    userDetail: state.profile.userDetail,
});

export default reduxForm({})(withRouter(connect(mapStateToProps, {})(SubmitButtons)));
